import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { logEvent } from 'firebase/analytics'
import { analytics } from '../firebase' // Ensure this path is correct
import '../styles/capcam.css' // Ensure correct path

function CapCam ({ language }) {
  useEffect(() => {
    // Log the event when the component mounts
    logEvent(analytics, 'page_capcam', {
      page_title: 'CapCam - 3D LiDAR Scanner',
      page_location: window.location.href,
      page_path: window.location.pathname,
      page_referrer: document.referrer,
      browser_language: navigator.language || navigator.userLanguage,
      browser_info: navigator.userAgent,
      trigger_time: new Date().toISOString(),
      user_locale: navigator.language || navigator.userLanguage,
      user_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
    })
  }, [])

  return (
    <main className='capcam-page'>
      <div className='background' style={{ backgroundColor: '#1a1a1a' }}>
        {/* Removed background image */}
      </div>
      <div className='content'>
        <h1>
          {language === 'en'
            ? 'CapCam - 3D LiDAR Scanner'
            : 'CapCam - 3D扫描仪'}
        </h1>
        <h2>
          {language === 'en'
            ? 'Capture Reality into Metaverse'
            : '口袋中的3D扫描仪'}
        </h2>
        <Link to='/'>
          <button>{language === 'en' ? 'Get the App' : '获取应用'}</button>
        </Link>
      </div>
      <div className='video-preview'>
        <video autoPlay loop muted playsInline>
          <source src={require('../assets/preview.mp4')} type='video/mp4' />
          Your browser does not support the video tag.
        </video>
      </div>
    </main>
  )
}

export default CapCam
