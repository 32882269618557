import React from 'react'
import '../styles/main.css' // Ensure correct path
import { Link } from 'react-router-dom'
import backgroundImage from '../assets/background.webp' // Adjust path as needed

const Main = ({ language }) => {
  return (
    <main className='main'>
      <div className='background'>
        <img
          src={backgroundImage}
          alt='Background'
          className='background-image'
        />
      </div>
      <div className='content'>
        <h1>{language === 'en' ? 'Introducing CapCam' : 'CapCam'}</h1>
        <h2>
          {language === 'en'
            ? 'Capture Reality into Metaverse.'
            : '口袋中的3D扫描仪'}
        </h2>
        <Link to='/capcam'>
          <button>{language === 'en' ? 'Try CapCam' : '试用CapCam'}</button>
        </Link>
      </div>
    </main>
  )
}

export default Main
