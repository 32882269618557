import React, { useEffect, useState } from 'react'
import { logEvent } from 'firebase/analytics'
import { analytics } from '../firebase' // Ensure this path is correct
import '../styles/privacy.css' // Ensure correct path

// Import the text files
import privacyEN from '../assets/privacy-en.txt'
import privacyCN from '../assets/privacy-zh.txt'

function Privacy ({ language }) {
  const [privacyContent, setPrivacyContent] = useState('')
  useEffect(() => {
    // Log the event when the component mounts
    logEvent(analytics, 'privacy_page', {
      page_title: 'privacy',
      page_location: window.location.href,
      page_path: window.location.pathname,
      page_referrer: document.referrer,
      browser_language: navigator.language || navigator.userLanguage,
      browser_info: navigator.userAgent,
      trigger_time: new Date().toISOString(),
      user_locale: navigator.language || navigator.userLanguage,
      user_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
    })
  }, [])

  useEffect(() => {
    // Load content based on language
    const loadContent = async () => {
      try {
        // Force reload by adding timestamp to prevent caching
        const textFile = language === 'en' ? privacyEN : privacyCN
        const response = await fetch(`${textFile}?t=${Date.now()}`)
        const text = await response.text()
        setPrivacyContent(text)
      } catch (error) {
        console.error('Error loading privacy content:', error)
        setPrivacyContent(
          language === 'en'
            ? 'Error loading privacy policy'
            : '加载隐私政策时出错'
        )
      }
    }

    // Call loadContent immediately when language changes
    loadContent()
  }, [language])

  const privacyTitle = language === 'en' ? 'CapCam Privacy' : 'CapCam 隐私政策'

  return (
    <main className='privacy-page'>
      <div className='content'>
        <h1 className='privacy-title'>{privacyTitle}</h1>
        <pre className='privacy-content'>{privacyContent}</pre>
      </div>
    </main>
  )
}

export default Privacy
