// hecate.js
import '../styles/hecate.css'

import React, { useState, useEffect } from 'react'
import Modal from 'react-modal'
import AdSense from 'react-adsense'
import compress from 'browser-image-compression'
import leftImage from '../assets/hecate/left_image.png'
import rightImage from '../assets/hecate/right_image.png'
import API_BASE_URL from '../config'

import { analytics } from '../firebase'
import { logEvent } from 'firebase/analytics'
import { Helmet } from 'react-helmet'

Modal.setAppElement('#root')

const Hecate = ({ language }) => {
  const [sourceImage, setSourceImage] = useState(null)
  const [targetImage, setTargetImage] = useState(null)
  const [taskId, setTaskId] = useState(null) // New state variable for task_id
  const [token, setToken] = useState(null) // New state variable for token
  const [swappedImage, setSwappedImage] = useState(null) // New state variable for swapped image
  const [isTaskCreated, setisTaskCreated] = useState(false)
  const [countdown, setCountdown] = useState(30)
  const [isDownloaded, setIsDownloaded] = useState(false)
  const [showWarning, setShowWarning] = useState(false)
  const TaskStatus = {
    created: 0,
    querying: 1,
    processing: 2,
    completed: 3,
    failed: 4,
    sendbacked: 5,
    aborted: 6
  }

  const handleSourceImageChange = async event => {
    const imageFile = event.target.files[0]
    const resizedImage = await resizeImageTo1MB(imageFile)
    setSourceImage(resizedImage)
  }

  const handleTargetImageChange = async event => {
    const imageFile = event.target.files[0]
    const resizedImage = await resizeImageTo1MB(imageFile)
    setTargetImage(resizedImage)
  }

  const resizeImageTo1MB = async imageFile => {
    const oneMB = 1024 * 1024 // 1MB in bytes

    if (imageFile.size <= oneMB) {
      // Image is already less than or equal to 1MB
      return imageFile
    }

    // Compress the image to 1MB
    const compressedImage = await compress(imageFile, {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: false
    })

    return compressedImage
  }

  const handleSwap = async () => {
    logEvent(analytics, 'toggle_swap')
    if (!sourceImage || !targetImage) {
      setShowWarning(true)
      logEvent(analytics, 'toggle_swap_warning')
      setTimeout(() => {
        setShowWarning(false)
      }, 3000) // Hide the warning after 3 seconds
      return
    }
    logEvent(analytics, 'toggle_swap_success')

    setCountdown(60)
    const formData = new FormData()
    formData.append('source_image', sourceImage)
    formData.append('target_image', targetImage)
    setisTaskCreated(true)
    try {
      const start_time = new Date().getTime()
      const response = await fetch(`${API_BASE_URL}/create_task`, {
        method: 'POST',
        body: formData
      })
      const time_cost = new Date().getTime() - start_time
      logEvent(analytics, 'create_task_cost_time', { time_cost })

      if (response.ok) {
        const data = await response.json()
        const taskId = data.task_id
        const token = data.token
        setTaskId(taskId)
        setToken(token)
      } else {
        setTaskId(null)
        setToken(null)
        setCountdown(0)
      }
    } catch (error) {
      setTaskId(null)
      setToken(null)
      setCountdown(0)
    }
  }

  useEffect(() => {
    Modal.setAppElement('#root') // Replace '#root' with your app's root element selector
  }, [])

  useEffect(() => {
    document.title =
      language === 'en' ? 'Hecate - Face Swap Online' : 'Hecate - 在线换脸'

    return () => {
      document.title = language === 'en' ? 'Tuyou Computing' : '图优运算'
    }
  }, [language])

  useEffect(() => {
    let countdownTimer

    if (isTaskCreated && !swappedImage) {
      countdownTimer = setInterval(() => {
        setCountdown(prevCountdown => prevCountdown - 1)
      }, 1000)
    }

    return () => {
      clearInterval(countdownTimer)
    }
  }, [isTaskCreated, swappedImage])

  useEffect(() => {
    if (isTaskCreated && taskId && token) {
      const interval = setInterval(async () => {
        try {
          const response = await fetch(`${API_BASE_URL}/status/${taskId}`)
          const data = await response.json()
          switch (data.status) {
            case TaskStatus.created:
              break
            case TaskStatus.querying:
              break
            case TaskStatus.processing:
              break
            case TaskStatus.completed:
              clearInterval(interval)
              const downloadResponse = await fetch(
                `${API_BASE_URL}/download_swapped_image/${taskId}`,
                {
                  headers: {
                    Authorization: `Bearer ${token}`
                  }
                }
              )
              if (downloadResponse.ok) {
                const blob = await downloadResponse.blob()
                const url = URL.createObjectURL(blob)
                setSwappedImage(url)
                setIsDownloaded(true)
              } else {
                setCountdown(0)
              }
              break
            case TaskStatus.failed:
              setCountdown(0)
              setInterval(null)
              break
            case TaskStatus.sendbacked:
              break
            case TaskStatus.aborted:
              break
            default:
          }
        } catch (error) {}
      }, 1000)

      return () => clearInterval(interval)
    }
  }, [
    isTaskCreated,
    taskId,
    token,
    TaskStatus.aborted,
    TaskStatus.completed,
    TaskStatus.created,
    TaskStatus.failed,
    TaskStatus.processing,
    TaskStatus.querying,
    TaskStatus.sendbacked
  ])

  const closePopup = () => {
    setisTaskCreated(false)
    setCountdown(60)
    setSourceImage(null)
    setTargetImage(null)
    setSwappedImage(null)
    setTaskId(null)
    setToken(null)
    setInterval(null)
    setIsDownloaded(false)
  }

  return (
    <div className='hecate'>
      <Helmet>
        <meta
          name='description'
          content={
            language === 'en'
              ? 'Hecate is a free online face swapping tool that allows you to easily swap faces between two images. Upload source and target images and get a swapped result within seconds'
              : 'Hecate是一款免费的在线换脸工具，让您轻松地在两张图片之间交换面孔。上传源图像和目标图像，几秒钟内即可获得换脸结果'
          }
        />
      </Helmet>
      <div className='div'>
        <p className='main-title'>
          {language === 'en'
            ? 'Hecate - Free Face Swap Online Tool'
            : 'Hecate - 免费在线换脸工具'}
        </p>
        <div className='images-line'>
          <div className='group-2'>
            <div className='rectangle-2' />
            <input
              type='file'
              accept='image/*'
              onChange={handleSourceImageChange}
              style={{ display: 'none' }}
              id='source-image-upload'
            />
            <label htmlFor='source-image-upload' className='image-upload-label'>
              {sourceImage ? (
                <img
                  className='left-image'
                  alt='Uploaded source face'
                  src={URL.createObjectURL(sourceImage)}
                />
              ) : (
                <img
                  className='left-image'
                  alt='Upload source face'
                  src={leftImage}
                />
              )}
              <div className='text-wrapper-7'>
                {language === 'en' ? 'Upload source face' : '上传源图像'}
              </div>
            </label>
          </div>

          <div className='group-3'>
            <div className='rectangle-3' />
            <input
              type='file'
              accept='image/*'
              onChange={handleTargetImageChange}
              style={{ display: 'none' }}
              id='target-image-upload'
            />
            <label htmlFor='target-image-upload' className='image-upload-label'>
              <div className='text-wrapper-8'>
                {language === 'en' ? 'Upload target face' : '上传目标图像'}
              </div>
              {targetImage ? (
                <img
                  className='right-image'
                  alt='Uploaded target face'
                  src={URL.createObjectURL(targetImage)}
                />
              ) : (
                <img
                  className='right-image'
                  alt='Upload target face'
                  src={rightImage}
                />
              )}
            </label>
          </div>
        </div>
        <div className='swap-button' onClick={handleSwap}>
          <div className='button-container'>
            <div className='text-wrapper-6'>SWAP</div>
          </div>
        </div>
        {showWarning && (
          <div className='warning-box'>
            Please upload the source and target images before swapping.
          </div>
        )}
      </div>
      <Modal
        isOpen={isTaskCreated}
        onRequestClose={closePopup}
        contentLabel='Processing Modal'
        className='popup-modal'
        overlayClassName='popup-overlay'
      >
        <div className='popup-content'>
          <button className='close-button' onClick={closePopup}>
            <p> &times; </p>
          </button>
          <div className='modal-header'>
            <p>Swapping faces...</p>
          </div>

          {!isDownloaded ? (
            <>
              {!swappedImage && (
                <>
                  <div className='countdown-container'>
                    {countdown > 0 ? (
                      <p>Swapping will finish within {countdown} seconds</p>
                    ) : (
                      <p>Oops, something went wrong.</p>
                    )}
                  </div>
                </>
              )}
            </>
          ) : (
            <>
              {swappedImage && (
                <div className='swapped-image-container'>
                  <img
                    src={swappedImage}
                    alt='Swapped'
                    className='swapped-image'
                  />
                </div>
              )}
              {swappedImage && (
                <div className='download-button-container'>
                  <button
                    className='download-button'
                    onClick={() => {
                      const link = document.createElement('a')
                      link.href = swappedImage
                      link.download = 'swapped_image.jpg'
                      link.click()
                      logEvent(analytics, 'user_download_swapped_image')
                    }}
                  >
                    Download
                  </button>
                </div>
              )}
            </>
          )}
          <AdSense.Google
            client='ca-pub-8711878664405558'
            slot='7796199461'
            style={{ display: 'block', width: '90%', height: '20%' }}
            format='auto'
            responsive='true'
          />
        </div>
      </Modal>
    </div>
  )
}

export default Hecate
